var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "bg_box" }),
    _c(
      "div",
      { staticClass: "centerbox" },
      [
        _c("headbox", {
          attrs: {
            title: "相关协议",
            bgColor: "#39394D",
            isborder: "true",
            isbackPath: true,
            path: "login_out"
          }
        }),
        _c(
          "div",
          {
            staticClass: "flex-between  bgwhite",
            on: { click: _vm.goPriceAgreements }
          },
          [_c("div", [_vm._v("线上购销框架合同")]), _vm._m(0)]
        ),
        _c(
          "div",
          {
            staticClass: "flex-between  bgwhite",
            on: { click: _vm.goRegisterAgreements }
          },
          [_c("div", [_vm._v("注册协议")]), _vm._m(1)]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "el-icon-arrow-right" })])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("i", { staticClass: "el-icon-arrow-right" })])
  }
]
render._withStripped = true

export { render, staticRenderFns }